import { GlobalStyles, GlobalStylesProps } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React, { lazy } from "react";
import { ToastContainer } from "react-toastify";

import globalStyles from "globalStyles";
import themeStyles from "themeStyles";

import FullPageLoading from "components/utils/FullPageLoading";

const Routes = lazy(() => import("Routes"));

export default function App() {
  return (
    <ThemeProvider theme={themeStyles}>
      <CssBaseline />
      <GlobalStyles styles={globalStyles as GlobalStylesProps["styles"]} />

      <React.Suspense fallback={<FullPageLoading />}>
        <Routes />

        <ToastContainer
          hideProgressBar
          newestOnTop
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          position="bottom-center"
          rtl={false}
          autoClose={3000}
          closeButton={false}
          limit={3}
        />
      </React.Suspense>
    </ThemeProvider>
  );
}
