import { createTheme } from "@mui/material/styles";

export default createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#D42600",
          "&$error": {
            color: "#D42600",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 14,
          marginRight: 14,
        },
      },
    },
  },
  typography: {
    fontSize: 12,
  },
  palette: {
    primary: {
      lighter: "#686869",
      light: "#686869",
      main: "#25282A",
      dark: "#25282A",
      contrastText: "#fff",
    },
    secondary: {
      lighter: "#F2F2F2",
      light: "#CCCCCC",
      main: "#777777",
      contrastText: "#fff",
    },
    warning: {
      lighter: "#FFF8E0",
      main: "#F9B300",
      contrastText: "#fff",
    },
    success: {
      main: "#B0D400",
      lighter: "#0DA16C",
      contrastText: "#fff",
    },
    error: {
      lighter: "#F9DFD9",
      main: "#D42600",
      contrastText: "#fff",
    },
    text: {
      primary: "#686869",
      secondary: "#232B3A",
    },
    common: {
      black: "#25282A",
      white: "#FFFFFF",
    },
    // background: {
    //   paper: "#F9F9F9",
    // },
  },
  shape: {
    borderRadius: 4,
  },
});
