// custom styles for overriding material-ui detail styles

const globalStyles = {
  "*": {
    boxSizing: "border-box",
    margin: 0,
    padding: 0,
  },
  body: {
    color: "#000",
    fontSize: "12px",
    margin: 0,
    fontFamily:
      " 'Roboto', '-apple-system', BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu','Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
    scrollBehavior: "smooth",
  },
  "#root": {
    background: "#FFFFFF",
    minHeight: "100vh",
    position: "relative",
  },

  // TextField
  ".MuiFormControl-root": {
    // disabled
    "& .MuiOutlinedInput-root.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.04)",
    },
  },
  ".MuiAutocomplete-root": {
    // disabled
    "& .MuiOutlinedInput-root.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.04)",
    },

    "& .MuiInputBase-root": {
      fontSize: 14,
      padding: "4px 9px !important",
    },
  },
  ".MuiFormControl-root, .MuiSelect-root, .MuiTextField-root, .MuiOutlinedInput-root": {
    "& label": {
      transform: "unset",
      transition: "unset",
      position: "relative",
      zIndex: 0,
      pointerEvent: "unset",
      fontSize: 12,
      marginBottom: "8px",
    },
    "& legend": {
      transform: "unset",
      transition: "unset",
      display: "none",
    },
    "& fieldset": {
      top: 0,
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
  "& .MuiInputBase-root": {
    fontSize: 14,
    input: {
      // padding: "7.5px 4px 7.5px 6px",
      // padding: "10px 4px 11px 6px",
      paddingTop: "12px",
      paddingBottom: "12px",
    },
  },

  // Dialog
  ".MuiDialog-root": {
    "& .MuiDialogContent-root": {
      borderBottom: "none !important",
    },
    "& .MuiDialogActions-root": {
      padding: "24px",
    },
  },

  // Tab
  ".MuiTabs-root": {
    "& .MuiTab-root": {
      textTransform: "none",
      fontSize: "10px",
      color: "#777777",
      fontWeight: 600,
    },

    "& .MuiBox-root": {
      borderColor: "#000",
    },
  },

  ".MuiInputLabel-root": {
    userSelect: "auto !important",
  },
};

export default globalStyles;
